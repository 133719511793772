<template>
    <div class="uploader">
        <template v-if="isMultiple">
            <el-upload action="#" :list-type="listType" :file-list="fileList" :on-change="chooseFile" :auto-upload="false" :http-request="uploadFile" accept="image/*">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                    <img class="avatar" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
        </template>
        <template v-else>
            <el-upload ref="upload" :multiple="false" :file-list="fileList" :on-change="chooseFile" :http-request="uploadFile" class="avatar-uploader" accept="image/*" :auto-upload="false" action="#" :show-file-list="false" list-type="picture">
                <img v-if="imageUrl && showPreview" :src="imageUrl" title="更换图片" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        listType: {
            type: String,
            default: "picture-card",
        },
        isMultiple: {
            type: Boolean,
            default: false,
        },
        showPreview: {
            type: Boolean,
            default: true,
        },
        imageList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            imageUrl: null,
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            fileList: [],
        };
    },
    watch: {
        imageList() {
            if (this.imageList.length > 0) {
                if (this.isMultiple) {
                    this.fileList = this.imageList;
                } else {
                    this.imageUrl = this.imageList[0].url;
                }
            }
        },
    },
    mounted() {},
    methods: {
        // 图片预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 删除图片
        handleRemove(file) {
            for (var i = 0; i < this.fileList.length; i++) {
                if (this.fileList[i].uid == file.uid) {
                    this.fileList.splice(i, 1);
                    console.log("handleRemove", file);
                    this.$emit("remove", file);
                    break;
                }
            }
        },
        // 选择文件
        chooseFile: function(file, fileList) {
            if (!this.isMultiple) {
                if (fileList.length > 1) {
                    fileList.splice(0, 1);
                }
            }
            this.imageUrl = file.url;
            console.log("chooseFile", file, file.url, fileList);
            this.uploadFile(file);
        },
        // 上传文件
        uploadFile: function(file) {
            const loading = this.$loading({
                lock: true,
                text: "文件上传中...",
            });
            var that = this;
            this.$http({
                url: "/sys/getSignature",
                data: {
                    fileName: file.name,
                },
            }).then((res) => {
                const OSS = require("ali-oss");
                // 调用后台接口返回过来的授权参数AccessKeyId、AccessKeySecret、SecurityToken
                let client = new OSS({
                    accessKeyId: res.data.AccessKeyId,
                    accessKeySecret: res.data.AccessKeySecret,
                    stsToken: res.data.SecurityToken,
                    region: "oss-cn-hangzhou", // oss地区
                    bucket: res.data.Bucket,
                });
                // 定义上传文件的名字+后缀
                const storeAs = res.data.ObjectName;
                // 调用上传接口
                client
                    .multipartUpload(storeAs, file.raw)
                    .then(function(response) {
                        loading.close();
                        if (response.res.status === 200) {
                            console.log("Upload Success " + storeAs);
                            file.imageUrl = storeAs;
                            that.fileList.push(file);
                            that.$emit("success", storeAs);
                        }
                    })
                    .catch(function(err) {
                        loading.close();
                        console.error("Upload Error: ", err);
                    });
            });
        },
    },
};
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
}
.avatar {
    width: 148px;
    height: 148px;
    display: block;
    background-size: cover;
}
</style>
